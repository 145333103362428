import type { HostPool } from "@warrenio/api-spec/spec.oats.gen";

export const hostPool1AutoId = "3590345e-0507-49d7-8e69-7fb16311a3a5";
export const hostPool1FakeId = "00000000-0000-0000-0000-000167bb0a39";
export const hostPool1PerformanceId = "d2fc6881-9316-46d7-b515-b6cddaeee927";

export const hostPool2EconomyId = "00000000-0000-0000-0000-00020e92ea50";

export const hostPools1: HostPool[] = [
    {
        created_at: "2022-12-28 13:50:52",
        description: "Performance storage",
        is_default_designated: true,
        name: "Fast disk",
        storage_pool_uuid: "f990fcb2-d6d8-432c-b676-94166ec789ef",
        ui_position: 2,
        updated_at: "2024-02-14 14:44:20",
        uuid: hostPool1PerformanceId,
    },
    {
        created_at: "2023-09-01 15:11:21",
        description: '"standard" storage',
        is_default_designated: false,
        name: "Automatically added Pool",
        storage_pool_uuid: "58936ca0-a1b5-4e5d-9816-609feceaacbc",
        ui_position: 0,
        updated_at: "2024-01-25 14:39:58",
        uuid: hostPool1AutoId,
    },
    {
        created_at: "2020-09-30 11:05:56",
        description: "Old hardware (fake HVs)",
        is_default_designated: false,
        name: "Fake Hosts",
        storage_pool_uuid: "58936ca0-a1b5-4e5d-9816-609feceaacbc",
        ui_position: 1,
        updated_at: "2023-12-15 14:46:11",
        uuid: hostPool1FakeId,
    },
];

export const hostPools2: HostPool[] = [
    {
        created_at: "2023-10-23 09:45:42",
        description: "High speed block storage, performance oriented CPU",
        is_default_designated: false,
        name: "Performance",
        storage_pool_uuid: "6d48075c-4ef3-4ba3-993b-c1c09812a4b6",
        ui_position: 0,
        updated_at: "2023-11-27 12:24:53",
        uuid: "ae2bd247-9dba-4176-9b25-2693efa1014a",
    },
    {
        created_at: "2020-09-30 11:05:56",
        description: "Economy compute and storage",
        is_default_designated: true,
        name: "Standard",
        storage_pool_uuid: "056217b8-bf52-42ef-a85a-d1a0f25b7093",
        ui_position: 2,
        updated_at: "2023-11-27 12:24:53",
        uuid: hostPool2EconomyId,
    },
];
