import type { BillingAccount, BillingAdditionalData } from "@warrenio/api-spec/spec.oats.gen";
import type { PaymentMethodId } from "../../modules/billing/PaymentMethod.tsx";
import {
    defaultBillingAccountId,
    genId,
    preClearBillingAccountId,
    prePendingBillingAccountId,
    preRecurringBillingAccountId,
} from "./billingAccountIds.ts";
import { mockCreditCard, mockInvoiceCard, mockMasterCard, mockVisaCard } from "./cardsExample";

/** Fields are forced to this on account creation */
export const createFieldValues = {
    is_deleted: false,
    is_default: false,
    is_recurring_payment_enabled: false,
    is_active: true,

    can_pay: false,

    credit_amount: 0,
    unpaid_amount: 0,
    running_totals: {
        credit_amount: -0.0,
        credit_available: 0,
        discount_amount: -0.0,
        ongoing: 0.0,
        subtotal: 0.0,
        total: 0.0,
        total_before_tax: 0.0,
        vat_tax: 0.0,
    },
    precalc_credit_amount: 0.0,
    precalc_days_in_debt: 0,
    precalc_ongoing: 0.0,

    paying_by_invoice: false,
    send_invoice_email: true,

    discount_percentage: 0,
    vat_percentage: 20,
} satisfies Partial<BillingAccount>;

/** Most common & useful default values for billing accounts */
const common = {
    ...createFieldValues,

    address_line1: "1 Street Road",

    city: "The City",
    country: "EE",
    county: "The County",

    created: 1679595484,

    company_name: "",
    company_reg_code: "",
    company_vat_number: "",

    customer_name: "",

    email: "common@warren.io",

    lang: "en",

    referral_share_code: "unset",

    reseller: "Warren Demo",

    site: "warren",
    user_id: 136,
    low_balance_notice_settings: {
        is_enabled: true,
    },
} satisfies Partial<BillingAccount>;

/** Slightly different just for extra test coverage  */
const common2 = {
    ...common,

    address_line1: "",

    email: "common2@warren.io",

    country: "MQ",

    company_name: "The Company",
    company_reg_code: "12345",
    company_vat_number: "VAT555",

    customer_name: "Dude",

    recurring_payment_amount: 12,
    recurring_payment_threshold: 34,

    vat_percentage: 0,
    low_balance_notice_settings: {
        is_enabled: false,
    },
} satisfies Partial<BillingAccount>;

const exampleAdditional = makeAdditionalData({ link_methods: ["duitku::BK", "omise::promptpay", "omise::truemoney"] });

const emptyAdditional = makeAdditionalData({ link_methods: [] });

const referralPromotions: BillingAccount["eligible_promotions"] = [
    {
        credit_type: "first_free",
        amount: 12.0,
    },
    {
        credit_type: "referral_receiver",
        amount: 17.0,
        top_up_requirement: 11.0,
        top_up_done: 0.0,
    },
];

//#region Example billing accounts
export const postPaymentClear: BillingAccount = {
    ...common,
    title: "Post-payment Clear",
    id: defaultBillingAccountId,

    additional_data: exampleAdditional,
    advertise_free_credit: true,
    allow_debt: true,
    can_pay: true,
    credit_amount: 100.0,
    discount_percentage: 100,
    precalc_credit_amount: 100.0,
    precalc_ongoing: 100.0,
    primary_card: mockVisaCard,
    restriction_level: "CLEAR",
    running_totals: {
        credit_amount: -0.0,
        credit_available: 100.0,
        discount_amount: -957.33788,
        ongoing: 100.0,
        subtotal: 957.33788,
        total: 0.0,
        total_before_tax: 0.0,
        vat_tax: 0.0,
    },
    unpaid_amount: 678.79,
};

export const prePaymentClear: BillingAccount = {
    ...common2,
    title: "Pre-payment Clear",
    id: preClearBillingAccountId,

    additional_data: exampleAdditional,
    advertise_free_credit: true,
    allow_debt: false,
    can_pay: true,
    credit_amount: 100.0,
    discount_percentage: 100,
    is_default: true,
    precalc_credit_amount: 100.0,
    precalc_ongoing: 100.0,
    primary_card: mockVisaCard,
    restriction_level: "CLEAR",
    running_totals: {
        credit_amount: -0.0,
        credit_available: 100.0,
        discount_amount: -957.33788,
        ongoing: 100.0,
        subtotal: 957.33788,
        total: 0.0,
        total_before_tax: 0.0,
        vat_tax: 0.0,
    },
    // NB: Testing that this is not shown in frontend
    unpaid_amount: 123.45,
    low_balance_notice_settings: {
        is_enabled: true,
        threshold: 50,
    },
};

export const prePaymentRecurring: BillingAccount = {
    ...prePaymentClear,
    title: "Pre-payment Recurring",
    id: preRecurringBillingAccountId,

    is_default: false, // there can be only one default BA

    is_recurring_payment_enabled: true,
    recurring_payment_amount: 123,
    recurring_payment_threshold: 345,
};

export const postPaymentLimited: BillingAccount = {
    ...common2,
    title: "Post-payment Limited",
    id: genId(),

    additional_data: emptyAdditional,
    allow_debt: true,
    can_pay: true,
    credit_amount: 104.97,
    precalc_credit_amount: 104.97,
    precalc_ongoing: 103.14,
    primary_card: mockMasterCard,
    restriction_level: "LIMITED",
    running_totals: {
        credit_amount: -1.83,
        credit_available: 104.97,
        discount_amount: -0.0,
        ongoing: 103.14,
        subtotal: 1.83,
        total: 0.0,
        total_before_tax: 0.0,
        vat_tax: 0.0,
    },
    suspend_reason: "",
    unpaid_amount: 0.07,
};

export const prePaymentLimited: BillingAccount = {
    ...common,
    title: "Pre-payment Limited",
    id: genId(),

    additional_data: emptyAdditional,
    allow_debt: false,
    can_pay: true,
    credit_amount: 104.97,
    precalc_credit_amount: 104.97,
    precalc_ongoing: 103.14,
    primary_card: mockMasterCard,
    restriction_level: "LIMITED",
    running_totals: {
        credit_amount: -1.83,
        credit_available: 104.97,
        discount_amount: -0.0,
        ongoing: 103.14,
        subtotal: 1.83,
        total: 0.0,
        total_before_tax: 0.0,
        vat_tax: 0.0,
    },
    suspend_reason: "",
    unpaid_amount: 0.07,
    low_balance_notice_settings: {
        is_enabled: true,
        threshold: 10,
    },
};

export const prePaymentPending: BillingAccount = {
    ...common2,
    title: "Pre-payment Pending",
    id: prePendingBillingAccountId,

    additional_data: emptyAdditional,

    allow_debt: false,
    can_pay: false,
    restriction_level: "FROZEN",
    is_active: false,
    status: "PENDING_VALIDATION",

    eligible_promotions: referralPromotions,
};

export const invoicePost: BillingAccount = {
    ...common,
    title: "Invoice Post",
    id: genId(),

    allow_debt: true,
    can_pay: true,
    credit_amount: 0,
    paying_by_invoice: true,
    primary_card: mockInvoiceCard,
    restriction_level: "CLEAR",
    unpaid_amount: 12.34,
};

export const postPaymentSuspended: BillingAccount = {
    ...common2,
    title: "Post-payment Suspended",
    id: genId(),

    additional_data: exampleAdditional,
    allow_debt: true,
    credit_amount: 10374.57,
    email: "inactive@warren.io",
    is_active: false,
    precalc_credit_amount: 10374.57,
    precalc_ongoing: 10077.117924,
    restriction_level: "FROZEN",
    running_totals: {
        credit_amount: -297.452076,
        credit_available: 10374.57,
        discount_amount: -0.0,
        ongoing: 10077.117924,
        subtotal: 297.452076,
        total: 0.0,
        total_before_tax: 0.0,
        vat_tax: 0.0,
    },
    suspend_reason: "Just in case",
};

export const prePaymentSuspended: BillingAccount = {
    ...common,
    title: "Pre-payment Suspended",
    id: genId(),

    additional_data: exampleAdditional,
    allow_debt: false,
    credit_amount: 10374.57,
    email: "inactive@warren.io",
    is_active: false,
    precalc_credit_amount: 10374.57,
    precalc_ongoing: 10077.117924,
    restriction_level: "FROZEN",
    running_totals: {
        credit_amount: -297.452076,
        credit_available: 10374.57,
        discount_amount: -0.0,
        ongoing: 10077.117924,
        subtotal: 297.452076,
        total: 0.0,
        total_before_tax: 0.0,
        vat_tax: 0.0,
    },
    suspend_reason: "Just in case",
    low_balance_notice_settings: {
        is_enabled: false,
        threshold: 5,
    },
};

export const advertiseFreeCreditPre: BillingAccount = {
    ...common2,
    title: "Advertise Free Credit Pre",
    id: genId(),

    advertise_free_credit: true,
    allow_debt: false,

    credit_amount: 0,
    eligible_promotions: [],
    restriction_level: "FROZEN",
};

export const advertiseFreeCreditPost: BillingAccount = {
    ...advertiseFreeCreditPre,
    title: "Advertise Free Credit Post",
    id: genId(),

    advertise_free_credit: true,
    allow_debt: true,
    primary_card: mockCreditCard,
};

export const signupCampaignCredit: BillingAccount = {
    ...common2,
    title: "Signup Campaign Credit Pre",
    id: genId(),

    advertise_free_credit: true,
    allow_debt: false,
    credit_amount: 0,
    eligible_promotions: [
        {
            credit_type: "first_free",
            amount: 12.0,
        },
        {
            credit_type: "signup_campaign",
            amount: 20,
        },
    ],
    primary_card: mockCreditCard,
    referral_code: "autumn2022",
    restriction_level: "FROZEN",
};

export const referralProgramCredit: BillingAccount = {
    ...common,
    title: "Referral Program Credit Pre",
    id: genId(),

    advertise_free_credit: true,
    allow_debt: false,
    credit_amount: 0,
    eligible_promotions: referralPromotions,
    primary_card: mockCreditCard,
    referral_code: "0bnmvx",
    restriction_level: "FROZEN",
};

export const referralProgramNoFirstFree: BillingAccount = {
    ...common2,
    title: "Referral Program No first_free Pre",
    id: genId(),

    allow_debt: false,
    credit_amount: 13.0,
    eligible_promotions: [
        {
            credit_type: "referral_receiver",
            amount: 17.0,
            top_up_requirement: 11.0,
            top_up_done: 0.0,
        },
    ],
    open_level_override: "LIMITED",
    precalc_credit_amount: 13.0,
    precalc_ongoing: 13.0,
    referral_code: "0bnmvx",
    restriction_level: "FROZEN",
    running_totals: {
        credit_amount: -0.0,
        credit_available: 13.0,
        discount_amount: -0.0,
        ongoing: 13.0,
        subtotal: 0.0,
        total: 0.0,
        total_before_tax: 0.0,
        vat_tax: 0.0,
    },
};
//#endregion

const allBillingAccounts: BillingAccount[] = [
    postPaymentClear,
    prePaymentClear,
    prePaymentRecurring,
    prePaymentPending,
    invoicePost,
    postPaymentLimited,
    prePaymentLimited,
    postPaymentSuspended,
    prePaymentSuspended,
    advertiseFreeCreditPre,
    advertiseFreeCreditPost,
    signupCampaignCredit,
    referralProgramCredit,
    referralProgramNoFirstFree,
];

// Programmatically generate common dynamic fields
for (const ba of Object.values(allBillingAccounts)) {
    ba.created += ba.id * 1000;
    ba.referral_share_code = `rs${ba.id}`;
}

export const nonSuspendedBillingAccountsExample = allBillingAccounts.filter(
    (ba) => ba.is_active || ba.status === "PENDING_VALIDATION",
);

export const billingAccountsExample: BillingAccount[] = allBillingAccounts;

export const metalBillingAccountsExample: BillingAccount[] = [
    {
        ...prePaymentRecurring,
        recurring_payment_amount: 500,
        recurring_payment_threshold: 100,
    },
];

function makeAdditionalData(data: BillingAdditionalData & { link_methods: PaymentMethodId[] }): string {
    return JSON.stringify(data);
}
